import React, {useState, useContext, createContext, useEffect} from 'react';
import styled from 'styled-components';
import SupportMailIcon from 'lib/icons/SupportMailIcon';
import {useLocation} from 'react-router-dom';
import useCompany from './hooks/useCompany';

const FloatingButton = styled('div')`
    ${({disabled}) => (disabled ? 'pointer-events: none;opacity:0.4;' : '')}
    cursor: pointer;
    position: fixed;
    transition: 0.2s ease all;
    font-size: 2rem;
    justify-content: center;
    position: absolute;
    bottom: 5px;
    right: 5px;
    svg {
        width: 80px;
        height: 80px;
        transition: 0.2s ease-in all;
        transform: scale(0.75);
        :hover {
            transform: scale(1);
        }
    }
`;
export const FloatingSupportButton = ({onClick, disabled}) => (
    <FloatingButton
        disabled={disabled}
        onClick={() => {
            onClick();
        }}>
        <SupportMailIcon />
    </FloatingButton>
);

const FloatingButtonContext = createContext(null);

export const FloatingSupportButtonProvider = ({children}) => {
    const location = useLocation();
    const [enabled, setEnabled] = useState(true);
    const [clicked, setClicked] = useState(false);
    const company = useCompany();

    useEffect(() => {
        if (location.pathname.startsWith('/editor')) {
            setEnabled(false);
        } else {
            setEnabled(true);
        }
    }, [location]);

    const onClick = () => {
        setClicked(true);
        window.location.href = `mailto:${company.slug}@spencer.co?subject=Meet Roger Support Request`;
        window.setTimeout(() => {
            setClicked(false);
        }, 2000);
    };
    return (
        <FloatingButtonContext.Provider value={{setEnabled}}>
            {children}
            {enabled && (
                <FloatingSupportButton disabled={clicked} onClick={onClick} />
            )}
        </FloatingButtonContext.Provider>
    );
};

export const useFloatingActionButtonContext = () =>
    useContext(FloatingButtonContext);
